import styled, { css } from 'styled-components'

export const Form = styled.form`
  width: 100%;
`;

const FormStyles = css`
  display: block;
  width: 100%;
  border: none;
  border-bottom: 2px solid #333333;
`;

export const Input = styled.input`
  ${ FormStyles }
  height: 3em;
  padding: 0 1em;
`;

export const TextArea = styled.textarea`
  ${ FormStyles }
  padding: 1em;
`;
