import styled from '@emotion/styled'

export const Button = styled.button`
  border: 2px solid ${ props => props.theme.colors.text };
  color: ${ props => props.theme.colors.text };
  background-color: transparent;
  padding: .75em 1.25em;
  font-weight: 500;
  background-size: 0 100%;
  background-repeat: no-repeat;
  -webkit-transition: all .4s ease;
  transition: all .4s ease-in-out;
  margin-top: 1em;

  &:hover {
    color: ${ props => props.theme.colors.background };;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(65%, ${ props => props.colorMode === 'dark' ? props.theme.colors.accent : props.theme.colors.text }), color-stop(1, ${ props => props.colorMode === 'dark' ? props.theme.colors.accent : props.theme.colors.text }));
    background-size: 100% 100%;
    cursor: pointer;
  }
`;
