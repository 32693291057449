import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Form, Input, TextArea } from '../components/Form.styles'
import { ContactSection } from '../components/Contact.styles'
import { Button } from '../components/Button.styles'

const Header = styled.h1`
  font-family: ${ props => props.theme.fonts.body };
  font-weight: 300;
  
  && {
    font-size: 28px;
    line-height: 1.35;
  }
`;

const ContactPage = () => {
  return (
      <Layout>
        <SEO title="Contact" />

        <ContactSection id="contact">
          <div className="contact-form-wrapper">
            <Header>
              Let's work together
            </Header>
            <Form
                className="contact-form"
                action="https://formspree.io/ninayujiri@gmail.com"
                method="POST"
            >
              <div className="columns">
                <div className="column">
                  <Input
                      type="text"
                      name="name"
                      id="full-name"
                      placeholder="Full name"
                      required=""
                  />
                </div>
                <div className="column">
                  <Input
                      type="email"
                      name="_replyto"
                      id="email-address"
                      placeholder="Email Address"
                      required=""
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextArea
                      rows="5"
                      name="message"
                      id="message"
                      placeholder="Message"
                      required=""
                  />
                </div>
              </div>
              <input type="hidden"
                     name="_subject"
                     id="email-subject"
                     value="Contact Form Submission"
              />
              <Button type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </ContactSection>
      </Layout>
  )
};

export default ContactPage
