import styled from '@emotion/styled'

export const ContactSection = styled.section`
  max-width: 1100px;
  min-height: 75vh;
  margin: auto;
  font-family: ${ props => props.theme.fonts.body };
  color: ${ props => props.theme.colors.text };

  textarea, input {
    font-family: ${ props => props.theme.fonts.body };
    color: #333;
  }
`;
